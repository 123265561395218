<template>
    <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol>
                <CSelect
                    label="Para Birimi: "
                    horizontal
                    placeholder="Seçiniz.."
                    type="text"
                    :options="activeCurrencies"
                    :value.sync="data.currencyId"
                    autocomplete="text"
                />
            </CCol>
            <CCol>
                <CInput
                    label="Fiyat: "
                    horizontal
                    type="number"
                    autocomplete="text"
                    v-model="data.cost"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                    label="Perakende Fiyatı: "
                    horizontal
                    type="number"
                    autocomplete="text"
                    v-model="data.retailPrice"
                />
            </CCol>
            <CCol>
                <CInput
                    label="Satış Fiyatı: "
                    horizontal
                    type="number"
                    autocomplete="text"
                    v-model="data.salePrice"
                />
            </CCol>
        </CRow>
   </CForm>
</template>

<script>
   export default{
       name: "ProductPriceForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       computed:{
        activeCurrencies: function() {
            let data = []
            this.$store.getters.activeCurrencies.map(r => data.push({value:r.id, label: r.name}))
            return data
        },
      },
       data: function(){
           return{
                data: this.params ? {...this.params} : {},
                attrExtFieldNames: ["Uzun ürün açıklaması...", "Product long description..."],
           }
       },
   }
</script>